import React, { useEffect, useState } from "react";
import axios from "axios";
import "jspdf-autotable";
import "../POchecker/POExpenseLabourList.css";
import jsPDF from "jspdf";
import logo from "./letterpad.png";
import './ExpenseList.css'
import DashboardHeader from "../Admin/Dashboard/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const ExpenseList = () => {
  const [allExpenses, setAllExpenses] = useState([]); // Consolidated array for all expenses
  const [filterType, setFilterType] = useState("all");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const itemsPerPage = 10;

  // Navigate Back
  const handleBack = () => {
    navigate(-1);
  };

  // Fetch Expenses and Consolidate Data
  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const params = { filter: filterType };

      if (filterType === "month" && year && month) {
        params.year = year;
        params.month = month;
      } else if (filterType === "dateRange" && fromDate && toDate) {
        params.fromDate = fromDate;
        params.toDate = toDate;
      }

      const response = await axios.get(
        "https://apenterprise-server.onrender.com/po-details/get-expenses",
        { params }
      );

      // Consolidate all expenses into one array
      const consolidatedExpenses = [];
      response.data.forEach((expense) => {
        expense.labourExpenses.forEach((labour) => {
          consolidatedExpenses.push({
            date: expense.date,
            type: "Labour",
            amount: labour.totalAmount,
            details: `Labours: ${labour.noOfLabours}, Rate: ${labour.rate}, Van Cost: ${labour.vanCost}, Desc: ${labour.description || "N/A"}`,
          });
        });
        expense.otherExpenses.forEach((other) => {
          consolidatedExpenses.push({
            date: expense.date,
            type: "Other",
            amount: other.amount,
            details: other.description || "N/A",
          });
        });
      });

      setAllExpenses(consolidatedExpenses);
      setTotalPages(Math.ceil(consolidatedExpenses.length / itemsPerPage));
    } catch (err) {
      setError("Failed to fetch expenses.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Calculate Total Amount
  const calculateTotalAmount = () => {
    return allExpenses.reduce((total, expense) => total + expense.amount, 0);
  };

  const numberToWords = (num) => {
    const a = [
      "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
      "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
      "seventeen", "eighteen", "nineteen",
    ];
    const b = [
      "", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy",
      "eighty", "ninety",
    ];
  
    const numToWords = (n) => {
      if (n < 20) return a[n];
      if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? " " + a[n % 10] : "");
      if (n < 1000)
        return (
          a[Math.floor(n / 100)] +
          " hundred" +
          (n % 100 ? " and " + numToWords(n % 100) : "")
        );
      if (n < 100000)
        return (
          numToWords(Math.floor(n / 1000)) +
          " thousand" +
          (n % 1000 ? " " + numToWords(n % 1000) : "")
        );
      if (n < 10000000)
        return (
          numToWords(Math.floor(n / 100000)) +
          " lakh" +
          (n % 100000 ? " " + numToWords(n % 100000) : "")
        );
      return (
        numToWords(Math.floor(n / 10000000)) +
        " crore" +
        (n % 10000000 ? " " + numToWords(n % 10000000) : "")
      );
    };
  
    return numToWords(num);
  };

  // Handle Page Change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Paginate Current Items
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = allExpenses.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    fetchExpenses();
    console.log(allExpenses)
  }, [filterType, year, month, fromDate, toDate]);

  if (loading) {
    return <p>Loading expenses...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }


    const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
  
    // Determine the ordinal suffix (st, nd, rd, th)
    const ordinal = (n) => {
      if (n > 3 && n < 21) return "th"; // Covers 11th - 19th
      switch (n % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
  
    return `${day}${ordinal(day)} ${month}, ${year}`;
  };


  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Add Logo
    const imgWidth = 180; // Full width of the page
    const imgHeight = 35; // Proportional height
    doc.addImage(logo, "PNG", 15, 8, imgWidth, imgHeight); // Add logo
  
    // Set Title
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
  
    let reportTitle = "Expense Report";
    if (filterType === "month" && year && month) {
      reportTitle += ` - ${new Date(year, month - 1).toLocaleString("en-US", { month: "long" })} ${year}`;
    } else if (filterType === "dateRange" && fromDate && toDate) {
      reportTitle += ` (From ${new Date(fromDate).toLocaleDateString("en-GB")} to ${new Date(toDate).toLocaleDateString("en-GB")})`;
    }
  
    doc.text(reportTitle, 105, imgHeight + 15, { align: "center" });
  
    // Prepare Table Data
    const tableColumn = ["Date", "Type", "Amount", "Details/Description"];
    const tableRows = [];
  
    allExpenses.forEach((expense) => {
      const subhoShare = (expense.amount * 0.4).toFixed(2);
      const companyShare = (expense.amount * 0.6).toFixed(2);
      
      tableRows.push([
        new Date(expense.date).toLocaleDateString("en-GB"),
        expense.type,
        expense.amount.toFixed(2),
        expense.details,
        // `Rs ${subhoShare}`,
        // `Rs ${companyShare}`,
      ]);
    });
  
    // Add Table
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: imgHeight + 20,
      theme: "striped",
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] }, // Blue header with white text
      alternateRowStyles: { fillColor: [230, 240, 255] }, // Light blue rows
      margin: { left: 14, right: 14 },
    });
  
    // Calculate Total Amounts
    const totalAmount = calculateTotalAmount();
    const totalSubhoShare = (totalAmount * 0.4).toFixed(2);
    const totalCompanyShare = (totalAmount * 0.6).toFixed(2);
  
    // Add Total Amount Section
    const totalSectionY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Total Amount: Rs ${new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(parseFloat(totalAmount))}`,
      14,
      totalSectionY
    );
  
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text(`(Rupees ${numberToWords(totalAmount)} only)`, 14, totalSectionY + 5);
  
    // Add Subho and Company Share
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`Subho Share (40%): Rs ${totalSubhoShare}`, 14, totalSectionY + 15);
    doc.text(`Company Share (60%): Rs ${totalCompanyShare}`, 14, totalSectionY + 20);
  
    // Add Footer with Report Generation Details
    const footerY = totalSectionY + 30;
    doc.setFontSize(8);
    doc.setFont("helvetica", "italic");
    const currentDate = formatDate(new Date());
    const currentTime = new Date().toLocaleTimeString();
    doc.text(
      `Report generated on: ${currentDate} at ${currentTime}`,
      14,
      footerY
    );
  
    // Save the PDF
    doc.save("Expense_Report.pdf");
  };
  
  

  return (
    <div>
      <DashboardHeader />
      <div className="labour-expenses-container">
        <FontAwesomeIcon
          onClick={handleBack}
          icon={faArrowLeftLong}
          size="2x"
          style={{ cursor: "pointer", marginBottom: "20px" }}
        />
        <div className="expense-list-container">
          <h2 className="labour-expenses-title">Total Expenses</h2>


<div className="filter-container">
  <label htmlFor="filterType" className="filter-label">Filter By:</label>
  <select
    id="filterType"
    value={filterType}
    onChange={(e) => setFilterType(e.target.value)}
    className="filter-select"
  >
    <option value="all">All</option>
    <option value="month">Month of a Year</option>
    <option value="dateRange">Date Range</option>
  </select>

  {filterType === "month" && (
    <div className="filter-date-group">
      <div className="filter-input-group">
        <label htmlFor="year" className="filter-label">Year:</label>
        <select
          id="year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="filter-select"
        >
          {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-input-group">
        <label htmlFor="month" className="filter-label">Month:</label>
        <select
          id="month"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          className="filter-select"
        >
          <option value="">Select Month</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString("en-US", { month: "long" })}
            </option>
          ))}
        </select>
      </div>
    </div>
  )}

  {filterType === "dateRange" && (
    <div className="filter-date-group">
      <div className="filter-input-group">
        <label htmlFor="fromDate" className="filter-label">From:</label>
        <input
          type="date"
          id="fromDate"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="filter-date-input"
        />
      </div>
      <div className="filter-input-group">
        <label htmlFor="toDate" className="filter-label">To:</label>
        <input
          type="date"
          id="toDate"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className="filter-date-input"
        />
      </div>
    </div>
  )}
</div>


          <button onClick={generatePDF} style={{ marginTop: "1em", marginLeft: "1em" }} className="generate-pdf-btn">
       Download PDF
     </button>

          {/* Expenses Table */}
          <div className="table-expense-div">
            <table className="labour-expenses-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Details/Description</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((expense, index) => (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{new Date(expense.date).toLocaleDateString("en-GB")}</td>
                      <td>{expense.type}</td>
                      <td>{expense.amount.toFixed(2)}</td>
                      <td>{expense.details}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data">
                      No expenses recorded.
                    </td>
                  </tr>
                )}
                {/* Total Row */}
                {currentPage === totalPages && (<tr>
                  <td colSpan="3" style={{ fontWeight: "bold", textAlign: "right" }}>
                    Total:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    Rs {new Intl.NumberFormat("en-IN", {maximumFractionDigits: 2,minimumFractionDigits: 2,}).format(parseFloat(calculateTotalAmount()))}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {numberToWords(calculateTotalAmount()).toUpperCase()} RUPEES ONLY
                  </td>
                </tr>)}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handlePageChange(i + 1)}
                  className={i + 1 === currentPage ? "active" : ""}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseList;








// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "jspdf-autotable";
// import jsPDF from "jspdf";
// import logo from "./letterpad.png";
// import "./ExpenseList.css";
// import DashboardHeader from "../Admin/Dashboard/DashboardHeader";
// import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeftLong, faSave, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

// const ExpenseList = () => {
//   const [allExpenses, setAllExpenses] = useState([]);
//   const [filterType, setFilterType] = useState("all");
//   const [year, setYear] = useState(new Date().getFullYear());
//   const [month, setMonth] = useState("");
//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");
//   const [editingExpense, setEditingExpense] = useState(null);
//   const [editedExpense, setEditedExpense] = useState(null);

//   const navigate = useNavigate();
//   const itemsPerPage = 10;

//   const BASE_URL = "https://apenterprise-server.onrender.com";

//   const handleBack = () => navigate(-1);

//   // Fetch Expenses and Consolidate Data
//   const fetchExpenses = async () => {
//     setLoading(true);
//     try {
//       const params = { filter: filterType };

//       if (filterType === "month" && year && month) {
//         params.year = year;
//         params.month = month;
//       } else if (filterType === "dateRange" && fromDate && toDate) {
//         params.fromDate = fromDate;
//         params.toDate = toDate;
//       }

//       const response = await axios.get(`${BASE_URL}/po-details/get-expenses`, { params });

//       const consolidatedExpenses = [];
//       response.data.forEach((expense) => {
//         expense.labourExpenses.forEach((labour) => {
//           consolidatedExpenses.push({
//             id: labour._id,
//             date: expense.date,
//             type: "Labour",
//             amount: labour.totalAmount,
//             details: `Labours: ${labour.noOfLabours}, Rate: ${labour.rate}, Van Cost: ${labour.vanCost}, Desc: ${labour.description || "N/A"}`,
//           });
//         });
//         expense.otherExpenses.forEach((other) => {
//           consolidatedExpenses.push({
//             id: other._id,
//             date: expense.date,
//             type: "Other",
//             amount: other.amount,
//             details: other.description || "N/A",
//           });
//         });
//       });

//       setAllExpenses(consolidatedExpenses);
//     } catch (err) {
//       setError("Failed to fetch expenses.");
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Edit Functions
//   const handleEdit = (expense) => {
//     setEditingExpense(expense.id);
//     setEditedExpense({ ...expense });
//   };

//   const handleCancelEdit = () => {
//     setEditingExpense(null);
//     setEditedExpense(null);
//   };

//   const handleInputChange = (e, field) => {
//     setEditedExpense((prev) => ({ ...prev, [field]: e.target.value }));
//   };

//   const handleSaveEdit = async () => {
//     try {
//       await axios.put(`${BASE_URL}/po-details/update-expense/${editedExpense.id}`, {
//         amount: editedExpense.amount,
//         details: editedExpense.details,
//       });

//       setAllExpenses((prevExpenses) =>
//         prevExpenses.map((expense) =>
//           expense.id === editedExpense.id ? { ...editedExpense } : expense
//         )
//       );

//       setEditingExpense(null);
//       setEditedExpense(null);
//     } catch (error) {
//       console.error("Failed to update expense:", error);
//       alert("Failed to update expense. Please try again.");
//     }
//   };

//   // Pagination
//   const totalPages = Math.ceil(allExpenses.length / itemsPerPage);
//   const handlePageChange = (page) => page > 0 && page <= totalPages && setCurrentPage(page);
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const currentItems = allExpenses.slice(startIndex, startIndex + itemsPerPage);

//   useEffect(() => {
//     fetchExpenses();
//   }, [filterType, year, month, fromDate, toDate]);

//   if (loading) return <p>Loading expenses...</p>;
//   if (error) return <p className="error-message">{error}</p>;

//   const generatePDF = () => {
//     const doc = new jsPDF();

//     doc.addImage(logo, "PNG", 15, 8, 180, 35);

//     let reportTitle = "Expense Report";
//     if (filterType === "month" && year && month) {
//       reportTitle += ` - ${new Date(year, month - 1).toLocaleString("en-US", { month: "long" })} ${year}`;
//     } else if (filterType === "dateRange" && fromDate && toDate) {
//       reportTitle += ` (From ${new Date(fromDate).toLocaleDateString("en-GB")} to ${new Date(toDate).toLocaleDateString("en-GB")})`;
//     }

//     doc.text(reportTitle, 105, 50, { align: "center" });

//     const tableColumn = ["Date", "Type", "Amount", "Details/Description"];
//     const tableRows = [];

//     allExpenses.forEach((expense) => {
//       tableRows.push([
//         new Date(expense.date).toLocaleDateString("en-GB"),
//         expense.type,
//         `Rs ${expense.amount.toFixed(2)}`,
//         expense.details,
//       ]);
//     });

//     doc.autoTable({
//       head: [tableColumn],
//       body: tableRows,
//       startY: 60,
//       theme: "striped",
//     });

//     doc.save("Expense_Report.pdf");
//   };

//   return (
//     <div>
//       <DashboardHeader />
//       <div className="labour-expenses-container">
//         <FontAwesomeIcon
//           onClick={handleBack}
//           icon={faArrowLeftLong}
//           size="2x"
//           style={{ cursor: "pointer", marginBottom: "20px" }}
//         />
//         <div className="expense-list-container">
//           <h2 className="labour-expenses-title">Total Expenses</h2>

//           <button onClick={generatePDF} className="generate-pdf-btn">Download PDF</button>

//           <div className="table-expense-div">
//             <table className="labour-expenses-table">
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>Date</th>
//                   <th>Type</th>
//                   <th>Amount</th>
//                   <th>Details/Description</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {currentItems.length > 0 ? (
//                   currentItems.map((expense, index) => (
//                     <tr key={expense.id}>
//                       <td>{startIndex + index + 1}</td>
//                       <td>{new Date(expense.date).toLocaleDateString("en-GB")}</td>
//                       <td>{expense.type}</td>
//                       <td>
//                         {editingExpense === expense.id ? (
//                           <input type="number" value={editedExpense.amount} onChange={(e) => handleInputChange(e, "amount")} />
//                         ) : (
//                           `Rs ${expense.amount.toFixed(2)}`
//                         )}
//                       </td>
//                       <td>
//                         {editingExpense === expense.id ? (
//                           <input type="text" value={editedExpense.details} onChange={(e) => handleInputChange(e, "details")} />
//                         ) : (
//                           expense.details
//                         )}
//                       </td>
//                       <td>
//                         {editingExpense === expense.id ? (
//                           <>
//                             <button onClick={handleSaveEdit}><FontAwesomeIcon icon={faSave} /> Save</button>
//                             <button onClick={handleCancelEdit}><FontAwesomeIcon icon={faTimes} /> Cancel</button>
//                           </>
//                         ) : (
//                           <button onClick={() => handleEdit(expense)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
//                         )}
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr><td colSpan="6">No expenses recorded.</td></tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExpenseList;
